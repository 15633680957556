const AfterJoinError = () => {
  return (
    <div className="h-[100vh] w-[100vw] text-[2.5vh] flex justify-center items-center relative flex-col bg-[#000000]">
      <div className="h-[70vh] aspect-[1.5]  rounded-[1rem] bg-[#FFF8E6]">
        <div className="px-[30px] w-full flex justify-start pt-[20px] ">
          <img src="/icons/fullLogo.svg" className="h-[1.7em] w-auto" alt="" />
        </div>
        <div className="w-full flex justify-center">
          <img src="/icons/afterJoin.svg" className="h-[45vh] w-auto" alt="" />
        </div>
        <div className="h-[20vh] bg-[#FFFF] rounded-b-[1rem] font-[500]  p-[30px] flex flex-col items-center justify-between  	w-full">
          <span className="text-[1.73em] text-[#000000] font-[500]">The link has has expired</span>
        </div>
      </div>
    </div>
  );
};
export default AfterJoinError;
