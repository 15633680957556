import React from 'react';

interface ConfirmModalProps {
  confirmModalPopUp: boolean;
  setConfirmModalPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  confirmMessage: string;
  confirmFunction: () => void;
}

export default function ConfirmModal({
  confirmModalPopUp,
  setConfirmModalPopUp,
  confirmMessage,
  confirmFunction
}: ConfirmModalProps) {
  if (!confirmModalPopUp) return null;

  return (
    <div className="fixed  inset-0 flex items-center z-[9999] justify-center">
      <div className="bg-[#313131] text-[#FFFF]  rounded-md h-[13.3rem] shadow-lg px-[2rem] w-auto">
        <div className="flex flex-col ">
          <div className="mt-[3.5rem] text-[1.5rem]">
            <p>{confirmMessage}</p>
          </div>
          <div className="flex justify-end space-x-2 mt-[2.3rem] text-[1.45rem]">
            <button
              className="px-4 py-2 w-[14.1rem] h-[3.1rem] rounded-[.73rem] bg-[#00B152] text-white  hover:bg-green-600"
              onClick={confirmFunction}
            >
              Yes
            </button>
            <button
              className="px-4 py-2 w-[14.1rem] h-[3.1rem]  rounded-[.73rem] border border-[#FF5454] text-[#FF5454] hover:bg-red-100"
              onClick={() => setConfirmModalPopUp(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
