import React from 'react';
import { createContext, useEffect, useState } from 'react';

const AuthContext = createContext({});
interface Whiteboard {
  id: string;
  name: string | null;
  hasAccessGiven: boolean;
}
export const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState({});
  const [previewConfigs, setPreviewConfigs] = useState({});
  const [initializing, setInitializing] = useState(true);

  const [whiteBoardList, setWhiteBoardList] = useState<Whiteboard[]>([]);
  const [showWhiteBoardSection, setShowWhiteBoardSection] = useState(false);
  const [currentWhiteBoardId, setCurrentWhiteBoardId] = useState('');


  useEffect(() => {
    try {
      const authLS = window.localStorage.getItem('auth');

      if (authLS) {
        const saveAuth = JSON.parse(authLS);
        setAuth(saveAuth);
      }
    } catch {
      //do nothing
    } finally {
      setInitializing(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        previewConfigs,
        setPreviewConfigs,
        initializing,
        whiteBoardList,
        setWhiteBoardList,
        showWhiteBoardSection,
        setShowWhiteBoardSection,
        currentWhiteBoardId,
        setCurrentWhiteBoardId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
