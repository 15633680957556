import React, { useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import useAuth from '../../hooks/useAuth';
import './whiteboard.css';
import { ENDPOINTS } from '../../services/urls';
import useAxiosPrivate from '../../hooks/useAxiosBase';
import CustomModal from './components/modal';
import { motion } from 'framer-motion';
interface WhiteboardProps {
  whiteBoardId: string;
  onHide: () => void;
}
interface Whiteboard {
  id: string;
  name: string | null;
  hasAccessGiven: boolean;
}

const Whiteboard: React.FC<WhiteboardProps> = ({ whiteBoardId, onHide }) => {
  const { auth, whiteBoardList, setWhiteBoardList, setCurrentWhiteBoardId, currentWhiteBoardId }: any = useAuth();

  const axios = useAxiosPrivate();
  const [collabText, setCollabText] = useState<string>('');
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [newWhiteBoardTitle, setNewWhiteBoardTitle] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tempText, setTempText] = useState('');
  const [tempId, setTempId] = useState('');
  const [sharedWhiteBoardId, setSharedWhiteBoardId] = useState('');
  // Initial setup of the connection
  useEffect(() => {
    const startConnection = async () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(ENDPOINTS.WHITEBOARDHUB)
        .configureLogging(LogLevel.Information)
        .build();

      newConnection.on('ReceiveDrawing', (userId: string, data: string) => {
        setCollabText(data);
        console.log('Text updated by user:', userId);
      });

      newConnection.on('AccessDenied', (message: string) => {
        alert(message);
      });

      newConnection.on('DrawingLocked', (message: string) => {
        alert(message);
      });

      try {
        await newConnection.start();
        console.log('Connected to SignalR Hub');
        setConnection(newConnection);
      } catch (error) {
        console.error('Connection error:', error);
      }
    };

    startConnection();

    return () => {
      connection?.stop();
    };
  }, []);

  useEffect(() => {
    const joinWhiteboard = async () => {
      if (connection) {
        try {
          await connection.invoke('JoinWhiteboard', whiteBoardId);
          getWhiteBoardIdData(whiteBoardId);
          console.log('Joined whiteboard:', whiteBoardId);
        } catch (error) {
          console.error('Error joining whiteboard or fetching content:', error);
        }
      }
    };

    joinWhiteboard();
  }, [whiteBoardId, connection]);
  const getWhiteBoardIdData = (whiteBoardId: string) => {
    axios.get(ENDPOINTS.WHITEBOARD + '/GetAllWhiteboardData?whiteboardId=' + whiteBoardId).then((res: any) => {
      if (!res.data) {
      } else {
        setCollabText(res.data.data);
        setTempText(res.data.data);
      }
    });
  };

  const broadcastTextUpdate = async (text: any) => {
    if (connection) {
      try {
        await connection.invoke('BroadcastDrawing', whiteBoardId, text, auth?.token?.replaceAll(' ', '+'));
        console.log('Text update sent successfully.');
      } catch (error) {
        console.error('Error sending text update:', error);
      }
    }
  };
  const broadcastTextUpdate2 = async (text: any) => {
    if (connection) {
      try {
        await connection.invoke('BroadcastDrawing', tempId, text, auth?.token?.replaceAll(' ', '+'));
        setIsLoading(false);
        console.log('Text update sent successfully.');
      } catch (error) {
        setIsLoading(false);
        console.error('Error sending text update:', error);
      }
    }
  };

  const handleAddNewWhiteboard = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSave = (inputValue: string) => {
    const whiteboard = whiteBoardList.find((item: Whiteboard) => item.name === null || item.name.trim() === '');

    if (whiteboard) {
      createWhiteBoardName(whiteboard.id, inputValue);
    } else {
      console.log('Cannot create more whiteboards.');
    }
  };

  const createWhiteBoardName = (id: string, name: string) => {
    const body = {
      whiteboardId: id,
      name: name
    };
    axios.post(ENDPOINTS.WHITEBOARD + '/AddWhiteboardName', body).then((res: any) => {
      if (!res.data.isSuccess) {
      } else {
        setCurrentWhiteBoardId(id);
        setWhiteBoardList((prevList: Whiteboard[]) =>
          prevList.map((item) => (item.id === id ? { ...item, name } : item))
        );
      }
    });
  };

  const handleApproveAccess = (id: string) => {
    const body = {
      whiteboardId: id
    };
    axios.post(ENDPOINTS.WHITEBOARD + '/ApproveWhiteboardAccess', body).then((res: any) => {
      if (!res.data.isSuccess) {
      } else {
        setSharedWhiteBoardId(id);
        setIsSharing(true);
      }
    });
  };
  const handleRevokeeAccess = (id: string) => {
    const body = {
      whiteboardId: id
    };
    axios.post(ENDPOINTS.WHITEBOARD + '/RevokeWhiteboardAccess', body).then((res: any) => {
      if (!res.data.isSuccess) {
      } else {
        setSharedWhiteBoardId('');
        setIsSharing(!isSharing);
      }
    });
  };
  const handleShare = () => {
    handleApproveAccess(currentWhiteBoardId);
  };

  const handleStopShare = () => {
    handleRevokeeAccess(sharedWhiteBoardId);
  };
  return (
    <div className="whiteboard-container">
      <div className="whiteboard-main">
        <div className="whiteboard-card">
          <h2 className="whiteboard-title">
            {whiteBoardList.find((board: Whiteboard) => board.id === whiteBoardId)?.name || 'Untitled Board'}
          </h2>
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <motion.div
                className="w-10 h-10 border-4 border-t-primary border-[#f3f3f3] rounded-full"
                animate={{ rotate: 360 }}
                transition={{
                  repeat: Infinity,
                  duration: 1,
                  ease: 'linear'
                }}
              ></motion.div>
            </div>
          ) : (
            <textarea
              id="collabTextBox"
              value={collabText}
              onChange={(e) => {
                setCollabText(e.target.value);
                setTempText(e.target.value);
                broadcastTextUpdate(e.target.value);
              }}
            />
          )}
        </div>

        <div className="button-container">
          {!isSharing ? (
            <div className="flex space-x-4">
              <button
                className="submit-button share-button rounded-full text-[#FFFFFF] bg-[#28a745] text-white px-4 py-3 font-semibold"
                onClick={handleShare}
              >
                Share
              </button>
              <button
                className="rounded-full border-danger text-[#FFFFFF] hover:bg-danger-50 font-semibold !bg-danger px-6 py-3"
                onClick={onHide}
              >
                Hide Board
              </button>
            </div>
          ) : (
            sharedWhiteBoardId === currentWhiteBoardId && (
              <div className="two-button-container">
                <button className="left-button rounded-md  !bg-[#ECFFF5] border border-danger text-danger hover:bg-danger-50 font-semibold cursor-default">
                  White board is being presented
                </button>
                <button
                  className="right-button rounded-full border border-danger text-[#FFFFFF] hover:bg-danger-50 font-semibold !bg-danger"
                  onClick={handleStopShare}
                >
                  Stop Share
                </button>
              </div>
            )
          )}
        </div>
      </div>

      <div className="whiteboard-sidebar">
        <ul>
          {whiteBoardList
            .filter((whiteboard: Whiteboard) => whiteboard.name !== null && whiteboard.name !== '')
            .map((whiteboard: Whiteboard, index: number) => (
              <motion.li
                key={index}
                className={`whiteboard-item p-3 mb-2 text-center rounded-lg font-semibold transition-colors duration-300 ${
                  whiteboard.id === currentWhiteBoardId ? 'bg-[#d4edda]' : 'bg-[#ecfff5] cursor-pointer'
                }`}
                animate={{
                  borderColor: whiteboard.id === currentWhiteBoardId ? '#28a745' : '#ecfff5',
                  borderWidth: '1px'
                }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                onClick={() => {
                  setTempId(whiteboard.id);
                  setIsLoading(true);
                  setTimeout(() => {
                    broadcastTextUpdate2(tempText);
                  }, 3000);
                  setCurrentWhiteBoardId(whiteboard.id);
                }}
              >
                {whiteboard.name}
              </motion.li>
            ))}
        </ul>
        {whiteBoardList.some((board: Whiteboard) => !board.name) && (
          <button
            onClick={handleAddNewWhiteboard}
            className="border border-primary text-primary text-[18px] font-semibold !bg-[#ECFFF5]"
          >
            Add a new white board
          </button>
        )}
        <CustomModal
          visible={isModalVisible}
          onClose={handleModalClose}
          onSave={handleSave}
          title="Add Title Name"
          newWhiteBoardTitle={newWhiteBoardTitle}
          setNewWhiteBoardTitle={setNewWhiteBoardTitle}
        />
      </div>
    </div>
  );
};

export default Whiteboard;
