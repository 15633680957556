import { useState, useEffect } from 'react';

const useCountdownTimer = (targetDate: string) => {
  const calculateTimeLeft = (date: string) => {
    const now = new Date();
    const then = new Date(date);
    const diff = (then.getTime() - now.getTime()) / 1000; // Difference in seconds

    if (diff <= 0) {
      return '0';
    }

    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = Math.floor(diff % 60);
    const padZero = (time: number) => (time < 10 ? '0' : '') + time;
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(intervalId);
  }, [targetDate]);

  return timeLeft;
};

export default useCountdownTimer;
