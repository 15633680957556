import { useEffect, useState } from 'react';

import { SelectionInput } from './components/selectionInput';
import { ENDPOINTS, PARENT_URL } from '../../services/urls';
import { useHistory } from 'react-router-dom';
import RadioSelection from './components/radioSelection';
import useAxiosPrivate from '../../hooks/useAxiosBase';
import useAuth from '../../hooks/useAuth';
import SVGLoader from '../../component/svgLoader';

function ChooseReason() {
  const history = useHistory();
  const axios = useAxiosPrivate();
  const { auth }: any = useAuth();
  const [selectedReason, setSelectedReason] = useState<any>(null);
  const [selectedSubReason, setSelectedSubReason] = useState<any>(null);
  const [otherSelected, setOtherSelected] = useState(false);

  const [exitReasons, setExitReasons] = useState([]);
  const [otherReason, setOtherReason] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    getReasons();
  }, []);
  const getReasons = () => {
    setIsLoading(true);
    axios
      .get(ENDPOINTS.GET_EXITREASONS)
      .then((res) => {
        setExitReasons(res.data.reasons);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const addReason = () => {
    if (!validateFeedback()) {
      setError('Please choose at least one reason');
      return;
    }
    setIsLoading(true);
    const body = {
      reason: otherReason !== '' ? null : selectedReason.reason,
      reasonId: otherReason !== '' ? null : selectedReason.reasonId,
      subReasonId: otherReason !== '' ? null : selectedSubReason.id,
      subReason: otherReason !== '' ? null : selectedSubReason.subReason,
      isOtherReason: otherReason !== '',
      otherReason: otherReason
    };

    axios.post(ENDPOINTS.ADD_REASON, body).then((res) => {
      if (res.data.isSuccess) {
        window.location.assign(PARENT_URL + '/dashboard/feedback/?' + 'sessionId=' + auth.sessionId);
      } else {
        setIsLoading(false);
      }
    });
  };
  const validateFeedback = () => {
    if (selectedSubReason || otherReason) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {isLoading ? (
        <SVGLoader loadingText=""></SVGLoader>
      ) : (
        <div className="h-[100vh] w-full flex flex-col bg-[#000000] items-center">
          {!selectedReason ? (
            <div className="mt-[7rem]  mobile:w-full mobile:px-[1rem] mobile:overflow-x-hidden">
              <span className="text-dark-text text-[1.5rem] text-center">Motive for exiting the interview?</span>
              {exitReasons?.map((reason: any) => (
                <SelectionInput
                  isLoading={false}
                  title={reason.reason}
                  classNames="mt-[32px] mobile:mt-[16px]"
                  onClick={() => setSelectedReason(reason)}
                  animateTime={0.2}
                />
              ))}
            </div>
          ) : (
            <div className="mt-[7rem]  mobile:w-full mobile:px-[1rem] mobile:overflow-x-hidden">
              <div className="flex items-center">
                <div>
                  <img
                    src="./icons/backArrow.svg"
                    alt=""
                    onClick={() => {
                      setSelectedReason(null);
                      setSelectedSubReason(null);
                    }}
                  />
                </div>

                <div className="ml-[.8rem]">
                  <span className="text-dark-text text-[1.5rem] text-center">{selectedReason.mainReasonQuestion}</span>
                </div>
              </div>
              {selectedReason?.subReasons.map((subReason: any, index: number) => (
                <RadioSelection
                  isLoading={false}
                  title={subReason.subReason}
                  isSelected={subReason.id === selectedSubReason?.id}
                  classNames="mt-[14px] mobile:mt-[16px]"
                  onClick={() => {
                    setSelectedSubReason(subReason);
                    setOtherSelected(false);
                    setOtherReason('');
                  }}
                  animateTime={index / 5}
                />
              ))}
              <div
                className="w-[27rem]  mobile:w-full mobile:h-[48px] mobile:px-[1rem] flex flex-col justify-start mt-[14px] rounded-[20px] mobile:rounded-[10.6px] border-[2px] mobile:border-[.8px] border-[#656565] bg-[#313131]	px-[41px]  py-[1rem]  cursor-pointer"
                style={{ background: 'rgb(21, 21, 21)' }}
                onClick={() => {
                  setOtherSelected(true);
                  setSelectedSubReason(null);
                }}
              >
                <div className="flex justify-between w-full">
                  <div>
                    <span className="text-dark-text text-[.86rem] text-start">Other</span>
                  </div>
                  <div>
                    {otherSelected ? (
                      <img src="/icons/radioSelected.svg" alt="" />
                    ) : (
                      <img src="/icons/radioDeselected.svg" alt="" />
                    )}
                  </div>
                </div>
                {otherSelected && (
                  <textarea
                    value={otherReason}
                    onChange={(e) => {
                      setOtherReason(e.target.value);
                    }}
                    className="w-full mt-[5px] p-[5px] text-[#FFFF] scrollbar-thin rounded-[4px] bg-[#303932] border-primary border-[1px] border-dashed resize-none outline-none"
                    name=""
                    rows={5}
                  ></textarea>
                )}
              </div>
              {error && <div className="text-danger text-[.8rem] mt-[14px]">{error}</div>}
              <button
                className="bg-primary mt-[14px] text-[#FFFF] rounded-[11px] h-[3.2rem] w-[27rem] font-[600] text-[1.3rem]"
                onClick={addReason}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ChooseReason;
