/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import CountdownTimer from './components/countDown';
import useCountdownTimer from './components/countDown';
import { useLocation } from 'react-router-dom';

const EarlyJoinError = () => {
  const param = useQuery();
  const startTime = param.get('startTime');
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const timeLeft = useCountdownTimer(startTime || '');

  return (
    <div className="h-[100vh] w-[100vw] text-[2.5vh] flex justify-center items-center relative flex-col bg-[#000000]">
      <div className="h-[70vh] aspect-[1.5]  rounded-[1rem] bg-[#DEFFEE]">
        <div className="px-[30px] w-full flex justify-start pt-[20px] ">
          <img src="/icons/fullLogo.svg" className="h-[1.7em] w-auto" alt="" />
        </div>
        <div>
          <img src="/icons/earlyJoin.svg" className="h-[45vh] w-auto" alt="" />
        </div>
        <div className="h-[20vh] bg-[#FFFF] rounded-b-[1rem] font-[500]  p-[30px] flex flex-col items-center justify-between  	w-full">
          <div className="text-[1.73em] flex ">
            <span className="text-[#000000]">
              {' '}
              {timeLeft !== '0' ? 'Your meeting will starts in : ' + ' ' : 'Your meeting started'}
            </span>

            {timeLeft !== '0' && <span className="text-[#00B152]"> {timeLeft}</span>}
          </div>
          {timeLeft !== '0' ? (
            <button className="h-[1.7em] px-[27px] w-[10em] mt-[20px] py-3 bg-[#e8e8e8] rounded-[.3em]  border-[#e7e7e7] justify-center items-center gap-2.5 inline-flex">
              <div>
                <span className="text-[#c4c4c4] text-[1em]  font-medium font-['Inter'] ">Get ready to join</span>
              </div>
            </button>
          ) : (
            <button className="h-[1.8em] px-[27px] w-[12.6em] mt-[20px] py-3 bg-primary rounded-[.3em]   justify-center items-center gap-2.5 inline-flex">
              <div>
                <span className="text-[#FFFF] text-[1em] font-medium font-['Inter'] ">Join Now</span>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default EarlyJoinError;
