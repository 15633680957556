import { useEffect } from 'react';
import { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { axiosIntercepted } from '../services';
import useAuth from './useAuth';
import { useHistory } from 'react-router-dom';

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  sent?: boolean;
}

const useAxiosPrivate = (): AxiosInstance => {
  //   const refresh = useRefreshToken();
  const history = useHistory();
  const { auth }: any = useAuth();

  useEffect(() => {
    const requestIntercept = axiosIntercepted.interceptors.request.use(
      (config: CustomAxiosRequestConfig) => {
        if (!config.headers['Auth-Token']) {
          config.headers['Auth-Token'] = `${auth?.token?.replaceAll(' ', '+')}`;
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error)
    );

    const responseIntercept = axiosIntercepted.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        const prevRequest = error.config as CustomAxiosRequestConfig;

        if ([401, 412].includes(error.response?.status as number) && !prevRequest?.sent) {
          prevRequest.sent = true;
          //   history.push('/error');
          //   const newRefreshToken = await refresh();

          //   if (!newRefreshToken) {
          //     return null;
          //   }

          //   prevRequest.headers['Auth-Token'] = `${newRefreshToken}`;
          //   return axiosIntercepted(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosIntercepted.interceptors.request.eject(requestIntercept);
      axiosIntercepted.interceptors.response.eject(responseIntercept);
    };
  }, [auth]);

  return axiosIntercepted;
};

export default useAxiosPrivate;
