import React, { useCallback, useContext, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Input } from 'antd';
import { ChatPrivilege } from '@zoom/videosdk';
import ZoomContext from '../../context/zoom-context';
import ChatMessageItem from './component/chat-message-item';
import ChatReceiverContainer from './component/chat-receiver';
import { useChat } from './hooks/useChat';
import './chat.scss';
import { IconFont } from '../../component/icon-font';

const { TextArea } = Input;
interface ChatContainerProps {
  setIsChatWindowOpen: (state: boolean) => void;
  isChatWindowOpen: boolean;
}
const ChatContainer = ({ setIsChatWindowOpen, isChatWindowOpen }: ChatContainerProps) => {
  const zmClient = useContext(ZoomContext);
  const chatClient = zmClient.getChatClient();
  const [chatDraft, setChatDraft] = useState<string>('');
  const chatWrapRef = useRef<HTMLDivElement | null>(null);
  const {
    records,
    receivers,
    privilege,
    chatUser,
    isHostOrManager,
    setChatUserId,
    sendMessage,
    sendFile,
    resendFile,
    downloadFile
  } = useChat(zmClient, chatClient);

  const onChatInput = (event: any) => {
    setChatDraft(event.target.value);
  };
  const onChatPressEnter = useCallback(
    (event: any) => {
      event.preventDefault();
      if (chatUser && chatDraft) {
        sendMessage(chatDraft);
        setChatDraft('');
      }
    },
    [sendMessage, chatUser, chatDraft]
  );
  useLayoutEffect(() => {
    if (chatWrapRef.current) {
      chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
    }
  }, [records]);
  return (
    <div className="chat-container  z-[9999]">
      <div className="chat-wrap">
        <div
          className="flex w-full justify-end items-center"
          onClick={() => {
            setIsChatWindowOpen(!isChatWindowOpen);
          }}
        >
          <button>
            <img src="./icons/close.svg" alt="" />
          </button>
        </div>
        <div className="chat-message-wrap scrollbar-thin mt-[1rem]" ref={chatWrapRef}>
          {chatClient.getHistory().length > 0 ? (
            <>
              {chatClient.getHistory().map((record) => (
                <ChatMessageItem
                  record={record}
                  currentUserId={zmClient.getSessionInfo().userId}
                  setChatUser={setChatUserId}
                  key={record.timestamp}
                  resendFile={resendFile}
                  downloadFile={downloadFile}
                />
              ))}
            </>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <div className="text-[#FFFF] text-[1.5rem]">
                <span>No new messages</span>
              </div>{' '}
            </div>
          )}
        </div>
        {ChatPrivilege.NoOne !== privilege || isHostOrManager ? (
          <>
            {/* <ChatReceiverContainer
              chatUsers={receivers}
              selectedChatUser={chatUser}
              isHostOrManager={isHostOrManager}
              chatPrivilege={privilege}
              setChatUser={setChatUserId}
              sendFile={sendFile}
            /> */}
            <div className="chat-message-box ">
              <input
                className="outline-none"
                onChange={(e) => {
                  onChatInput(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onChatPressEnter(e);
                  }
                }}
                value={chatDraft}
                placeholder="Type message here ..."
              />
              <button
                className=" flex justify-center  items-center h-[3rem] w-[3rem] bg-primary rounded-full "
                onClick={onChatPressEnter}
              >
                <IconFont type="icon-send"></IconFont>
              </button>
            </div>
          </>
        ) : (
          <div className="chat-disabled">Chat disabled</div>
        )}
      </div>
    </div>
  );
};

export default ChatContainer;
