import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import Home from './feature/home/home';
import Video from './feature/video/video';
import VideoSingle from './feature/video/video-single';
import VideoAttach from './feature/video/video-attach';
import Preview from './feature/preview/preview';
import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';
import LoadingLayer from './component/loading-layer';
import Chat from './feature/chat/chat';

import { MediaStream } from './index-types';
import './App.css';
import SVGLoader from './component/svgLoader';
import Error from './feature/error/authError';
import AuthError from './feature/error/authError';
import Feedback from './feature/feedback';
import useAuth from './hooks/useAuth';
import EarlyJoinError from './feature/error/earlyJoinError';
import AfterJoinError from './feature/error/afterJoinError';
import { PARENT_URL } from './services/urls';

interface AppProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    enforceVB?: string;
    customerJoinId?: string;
    lang?: string;
    useVideoPlayer?: string;
  };
}
const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
    logClient: any | undefined;
  }
}

function App(props: AppProps) {
  const {
    meetingArgs: {
      sdkKey,
      topic,
      signature,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      enforceVB,
      customerJoinId,
      lang,
      useVideoPlayer
    }
  } = props;
  const history = useHistory();
  const { initializing }: any = useAuth();
  const [loading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(false);
  const zmClient = useContext(ZoomContext);
  let webEndpoint: any;
  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const galleryViewWithAttach = Number(useVideoPlayer) === 1 && (window.crossOriginIsolated || galleryViewWithoutSAB);

  // if (galleryViewWithAttach) {
  //   console.log({
  //     galleryViewWithAttach,
  //     use: '<video-player-container> video tag render video',
  //     doc: 'https://marketplacefront.zoom.us/sdk/custom/web/modules/Stream.html#attachVideo'
  //   });
  // } else {
  //   console.log({
  //     galleryViewWithAttach,
  //     use: '<canvas>',
  //     doc: 'https://marketplacefront.zoom.us/sdk/custom/web/modules/Stream.html#startVideo'
  //   });
  // }

  useEffect(() => {
    const init = async () => {
      await zmClient.init('en-US', `${window.location.origin}/lib`, {
        webEndpoint,
        enforceMultipleVideos: galleryViewWithoutSAB,
        enforceVirtualBackground: vbWithoutSAB,
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: false
      });
    };
    init();
    // rejoinSession();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, []);
  const rejoinSession = async () => {
    const sessionInfo = localStorage.getItem('zoomSession');
    if (sessionInfo) {
      const { sessionName, signature, userName, password } = JSON.parse(sessionInfo);
      try {
        await joinSession(signature, sessionName);
      } catch (error) {
        console.error('Failed to recover session:', error);
        localStorage.removeItem('zoomSession');
      }
    }
  };

  const joinSession = async (signature: string, sessionName: string) => {
    setIsLoading(true);
    try {
      setLoadingText('Joining the session...');
      await zmClient.join(sessionName, signature, 'userName', password).catch((e) => {
        console.log(e);
      });
      localStorage.setItem(
        'zoomSession',
        JSON.stringify({
          sessionName,
          signature,
          userName: 'userName',
          password
        })
      );
      const stream = zmClient.getMediaStream();
      setMediaStream(stream);
      setIsSupportGalleryView(stream.isSupportMultipleVideos());
    } catch (e: any) {
      setIsLoading(false);
      message.error(e.reason);
    } finally {
      setIsLoading(false);
    }
  };
  const leaveSession = async () => {
    const auth = JSON.parse(localStorage.getItem('auth') || '');
    if (auth) {
      window.location.assign(PARENT_URL + '/dashboard/feedback/?' + 'sessionId=' + auth.sessionId);
    }
  };
  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          Modal.warning({
            title: 'Meeting ended',
            content: 'This meeting has been ended by host',
            style: {
              backgroundColor: '#f0f0f0' // Set the desired background color
            },
            afterClose() {
              leaveSession();
            }
          });
        }
      }
    },
    [isFailover, zmClient]
  );
  const onMediaSDKChange = useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  // const onLeaveOrJoinSession = useCallback(async () => {
  //   if (status === 'closed') {
  //     setIsLoading(true);
  //     await zmClient.join(topic, signature, name, password);
  //     setIsLoading(false);
  //   } else if (status === 'connected') {
  //     await zmClient.leave();
  //     message.warn('You have left the session.');
  //   }
  // }, [zmClient, status, topic, signature, name, password]);
  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  return (
    <div className="App">
      {loading && <SVGLoader loadingText={loadingText} />}
      {!loading &&
        (!initializing ? (
          <ZoomMediaContext.Provider value={mediaContext}>
            <Router>
              <Switch>
                {/* <Route
                path="/home"
                render={(props) => <Home {...props} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} />}
                exact
              /> */}
                <Route path={'/after-join'} component={AfterJoinError} />
                <Route path={'/early-join'} component={EarlyJoinError} />
                <Route path="/feedback" component={Feedback} />
                <Route path="/chat" component={Chat} />
                {/* <Route path="/command" component={Command} /> */}
                <Route
                  path="/video"
                  component={isSupportGalleryView ? (galleryViewWithAttach ? VideoSingle : Video) : VideoSingle}
                />
                {/* <Route path="/subsession" component={Subsession} /> */}
                <Route path="/preview" render={(props) => <Preview {...props} joinSession={joinSession} />} />
                <Route path="*" component={AuthError} />
              </Switch>
            </Router>
          </ZoomMediaContext.Provider>
        ) : (
          <></>
        ))}
    </div>
  );
}

export default App;
