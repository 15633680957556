import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { UpOutlined } from '@ant-design/icons';
import { IconFont } from '../../../component/icon-font';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
import ConfirmModal from './confirm-modal';
const { Button: DropdownButton } = Dropdown;
const { Item: MenuItem } = Menu;
interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick, onEndClick, isHost } = props;
  const [leaveConfirmModal, setLeaveConfirmModal] = useState(false);
  const [caringQuestionsModal, setCaringQuestionsModal] = useState(false);

  const handleLogout = () => {
    isHost ? onEndClick() : onLeaveClick();
  };
  return (
    <>
      <ConfirmModal
        confirmModalPopUp={leaveConfirmModal}
        setConfirmModalPopUp={setLeaveConfirmModal}
        confirmMessage={'Do you want to exit the meeting?'}
        confirmFunction={handleLogout}
      />

      <button
        className=" ml-[20px] flex justify-center relative items-center h-[3.75rem] w-[7em] bg-[#FF4949] text-[1rem] font-[600] text-[#FFFF] rounded-[3.7rem]"
        onClick={() => {
          setLeaveConfirmModal(true);
        }}
      >
        End Call
      </button>
    </>
  );
};

export { LeaveButton };
