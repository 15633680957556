import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { RouteComponentProps } from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import AvatarActionContext from './context/avatar-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useAvatarAction } from './hooks/useAvatarAction';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import ReportBtn from './components/report-btn';
import ShareView from './components/share-view';
import RemoteCameraControlPanel from './components/remote-camera-control';
import { SELF_VIDEO_ID } from './video-constants';
import useAuth from '../../hooks/useAuth';
import Whiteboard from './whiteboard';
import IntervieweeWhiteBoard from './intervieweeWhiteBoard';
import { AnimatePresence, motion } from 'framer-motion';
import { ENDPOINTS } from '../../services/urls';
import useAxiosPrivate from '../../hooks/useAxiosBase';
import './video.scss';
const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
  const [isRecieveSharing, setIsRecieveSharing] = useState(false);
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );
  const axios = useAxiosPrivate();
  const {
    auth,
    showWhiteBoardSection,
    currentWhiteBoardId,
    setCurrentWhiteBoardId,
    whiteBoardList,
    setShowWhiteBoardSection,
    setWhiteBoardList
  }: any = useAuth();
  const [connection, setConnection] = useState<HubConnection | null>(null);

  /**
   * position for self video
   */
  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === zmClient.getCurrentUserInfo()?.userId
  );
  let selfVideoLayout = null;
  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex];
    if (item && canvasDimension) {
      selfVideoLayout = { ...item, y: canvasDimension.height - item.y - item.height };
    }
  }
  const avatarActionState = useAvatarAction(zmClient, visibleParticipants);
  const networkQuality = useNetworkQuality(zmClient);
  useEffect(() => {
    const whiteboard =
      whiteBoardList.find((item: Whiteboard) => item.name && item.name.trim() !== '') ||
      whiteBoardList.find((item: Whiteboard) => item.name === null || item.name.trim() === '');
    let id = whiteboard ? whiteboard.id : null;
    setCurrentWhiteBoardId(id);
    const startConnection = async () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(ENDPOINTS.WHITEBOARDHUB)
        .configureLogging(LogLevel.Information)
        .build();

      newConnection.on('ReceiveDrawing', (userId: string, data: string) => {
        console.log('Text updated by user:', userId);
      });

      newConnection.on('AccessDenied', (message: string) => {
        alert(message);
      });

      newConnection.on('DrawingLocked', (message: string) => {
        alert(message);
      });

      try {
        await newConnection.start();
        console.log('Connected to SignalR Hub');
        await newConnection.invoke('JoinWhiteboard', id);
        console.log('Joined whiteboard:', id);
      } catch (error) {
        console.error('Connection error:', error);
      }

      setConnection(newConnection);
    };

    startConnection();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (auth.token && auth.role === '2') {
      getWhiteBoardIds();
      const interval = setInterval(() => {
        getWhiteBoardIds();
      }, 6000);

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [auth]);

  const getWhiteBoardIds = async () => {
    if (auth.token) {
      const url = ENDPOINTS.WHITEBOARD + '/GetWhiteBoardIds?sessionId=' + auth.sessionId;
      try {
        const response = await axios.get(url);
        const output = response.data;

        if (output && output.whiteboards) {
          setWhiteBoardList(output.whiteboards);

          // Check if any whiteboard has hasAccessGiven set to true
          const accessGrantedBoard = output.whiteboards.find((board: Whiteboard) => board.hasAccessGiven === true);

          if (accessGrantedBoard) {
            setShowWhiteBoardSection(true);
            setCurrentWhiteBoardId(accessGrantedBoard.id);
          } else {
            setShowWhiteBoardSection(false); // Optionally set this if no access is given
          }
        } else {
          console.log('No whiteboards found.');
        }
      } catch (error) {
        console.error('Error fetching whiteboard IDs:', error);
      }
    }
  };
  return (
    <div className="viewport">
      {/* {showWhiteBoardSection ? (
        auth.role === '1' ? (
          <AnimatePresence>
            <motion.div
              key="whiteboardsection"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, type: 'spring' }}
            >
              <Whiteboard whiteBoardId={currentWhiteBoardId} onHide={() => setShowWhiteBoardSection(false)} />
            </motion.div>
          </AnimatePresence>
        ) : (
          <AnimatePresence>
            <motion.div
              key="anothersection"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, type: 'spring' }}
            >
              <IntervieweeWhiteBoard whiteBoardId={currentWhiteBoardId} />
            </motion.div>
          </AnimatePresence>
        )
      ) : (
        <>
          <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
          <div
            className={classnames('video-container', {
              'video-container-in-sharing': isRecieveSharing
            })}
          >
            <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
            {selfVideoLayout && mediaStream?.isRenderSelfViewWithVideoElement() && (
              <video
                id={SELF_VIDEO_ID}
                className="self-video-tag"
                playsInline
                muted
                autoPlay
                style={{
                  display: 'block',
                  width: `${selfVideoLayout.width}px`,
                  height: `${selfVideoLayout.height}px`,
                  top: `${selfVideoLayout.y}px`,
                  left: `${selfVideoLayout.x}px`,
                  pointerEvents: 'none'
                }}
              />
            )}
            <AvatarActionContext.Provider value={avatarActionState}>
              <ul className="avatar-list">
                {visibleParticipants.map((user, index) => {
                  if (index > videoLayout.length - 1) {
                    return null;
                  }
                  const dimension = videoLayout[index];
                  const { width, height, x, y } = dimension;
                  const { height: canvasHeight } = canvasDimension;
                  return (
                    <Avatar
                      participant={user}
                      key={user.userId}
                      isActive={activeVideo === user.userId}
                      networkQuality={networkQuality[`${user.userId}`]}
                      style={{
                        width: `${width}px`,
                        height: `${height}px`,
                        top: `${canvasHeight - y - height}px`,
                        left: `${x}px`
                      }}
                    />
                  );
                })}
              </ul>
              <RemoteCameraControlPanel />
            </AvatarActionContext.Provider>
          </div>
        </>
      )} */}
      <div
        className={` z-[9999] ${
          showWhiteBoardSection && auth.role === '1' ? 'opacity-100' : 'opacity-0'
        } absolute top-0 left-0 w-full h-full  transition-opacity duration-500 ${
          showWhiteBoardSection && auth.role === '1' ? 'block' : 'hidden'
        }`}
      >
        <Whiteboard whiteBoardId={currentWhiteBoardId} onHide={() => setShowWhiteBoardSection(false)} />
      </div>

      <div
        className={`z-[9999] ${
          showWhiteBoardSection && auth.role !== '1' ? 'opacity-100' : 'opacity-0'
        } absolute top-0 left-0 w-full h-full  transition-opacity duration-500 ${
          showWhiteBoardSection && auth.role !== '1' ? 'block' : 'hidden'
        }`}
      >
        <IntervieweeWhiteBoard whiteBoardId={currentWhiteBoardId} />
      </div>

      <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
      <div
        className={classnames('video-container', {
          'video-container-in-sharing': isRecieveSharing
        })}
      >
        <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />
        {selfVideoLayout && mediaStream?.isRenderSelfViewWithVideoElement() && (
          <video
            id={SELF_VIDEO_ID}
            className="self-video-tag"
            playsInline
            muted
            autoPlay
            style={{
              display: 'block',
              width: `${selfVideoLayout.width}px`,
              height: `${selfVideoLayout.height}px`,
              top: `${selfVideoLayout.y}px`,
              left: `${selfVideoLayout.x}px`,
              pointerEvents: 'none'
            }}
          />
        )}
        <AvatarActionContext.Provider value={avatarActionState}>
          <ul className="avatar-list">
            {visibleParticipants.map((user, index) => {
              if (index > videoLayout.length - 1) {
                return null;
              }
              const dimension = videoLayout[index];
              const { width, height, x, y } = dimension;
              const { height: canvasHeight } = canvasDimension;
              return (
                <Avatar
                  participant={user}
                  key={user.userId}
                  isActive={activeVideo === user.userId}
                  networkQuality={networkQuality[`${user.userId}`]}
                  style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    top: `${canvasHeight - y - height}px`,
                    left: `${x}px`
                  }}
                />
              );
            })}
          </ul>
          <RemoteCameraControlPanel />
        </AvatarActionContext.Provider>
      </div>
      <VideoFooter className="video-operations" sharing selfShareCanvas={shareViewRef.current?.selfShareRef} />

      {totalPage > 1 && <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isRecieveSharing} />}
      <ReportBtn />
    </div>
  );
};

export default VideoContainer;
