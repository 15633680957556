import gsap from 'gsap';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
interface Props {
  title: string;
  classNames?: string;
  animateTime: number;
  onClick?: () => void;
  isLoading: boolean;
  isSelected: boolean;
}

export const RadioSelection = ({ title, classNames, onClick, animateTime, isLoading, isSelected }: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  const loaderRef = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  useEffect((): any => {
    if (loaderRef.current) {
      const animation = gsap.to(loaderRef.current, {
        backgroundPositionX: '100%',
        duration: 1.2,
        repeat: -1,
        ease: 'linear'
      });
      return () => animation.kill();
    }
  }, []);
  useEffect(() => {
    if (isSelected) {
      gsap.to(container.current, {
        borderColor: '#00B152',
        background: 'rgba(0, 177, 82, 0.05)',
        color: '#00B152'
      });
    } else {
      gsap.to(container.current, {
        borderColor: '#656565',
        background: '#151515',
        color: '#6D6D6D'
      });
    }
  }, [isSelected]);
  return (
    <motion.div
      initial={{ translateX: `90px` }}
      exit={{ border: isClicked ? '1px solid #00B152' : '' }}
      animate={{ translateX: 0 }}
      transition={{ duration: animateTime, ease: 'anticipate' }}
      ref={container}
      className={`w-[27rem] h-[3.4rem] mobile:w-full mobile:h-[48px] mobile:px-[1rem] flex items-center justify-between rounded-[20px] mobile:rounded-[10.6px] border-[2px] mobile:border-[.8px] mobile:border-[#656565] border-[#656565]  bg-[#313131]	px-[41px] ${classNames} cursor-pointer`}
      onClick={() => {
        onClick ? onClick() : () => {};
        setIsClicked(true);
      }}
    >
      {isLoading ? (
        <>
          <div
            ref={loaderRef}
            className="w-full h-2 bg-gradient-to-r from-transparent via-black to-transparent bg-[length:300%_100%] bg-[#6d6d6d] rounded-full"
            style={{
              backgroundSize: '300% 100%'
            }}
          ></div>
        </>
      ) : (
        <>
          <div>
            <span className="text-dark-text  text-[.86rem]">{title}</span>
          </div>
          <div>
            {isSelected ? (
              <img src="/icons/radioSelected.svg" alt="" />
            ) : (
              <img src="/icons/radioDeselected.svg" alt="" />
            )}
          </div>
        </>
      )}
    </motion.div>
  );
};

export default RadioSelection;
